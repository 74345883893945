import Accordion from './Accordion';
import BodyCopy from './BodyCopy';
import Breadcrumbs from './Breadcrumbs';
import Button from './Button';
import BynderWidget from './BynderWidget';
import Carousel from './Carousel';
import DefinitionList from './DefinitionList';
import DialogBanner from './DialogBanner';
import DoubleImage from './DoubleImage';
import Figure from './Figure';
import FlyinPanel from './FlyinPanel';
import Heading from './Heading';
import HeroWithContent from './HeroWithContent';
import Hyperlink from './Hyperlink';
import Icon from './Icon';
import Image from './Image';
import ImageWithHeaderAndContent from './ImageWithHeaderAndContent';
import KitList from './KitList';
import List from './List';
import Loading from './Loading';
import Modal from './Modal';
import Overlay from './Overlay';
import Paragraph, { P, ParagraphSet } from './Paragraph';
import Quote from './Quote';
import SectionHeading from './SectionHeading';
import SubNav from './SubNav';
import Transition from './Transition';
import TwoColumnLayout from './TwoColumnLayout';
import TwoColumnList from './TwoColumnList';
import Video from './Video';

export default {
  Accordion,
  BodyCopy,
  Breadcrumbs,
  Button,
  BynderWidget,
  Carousel,
  DefinitionList,
  DialogBanner,
  DoubleImage,
  Figure,
  FlyinPanel,
  Heading,
  HeroWithContent,
  Hyperlink,
  Icon,
  Image,
  ImageWithHeaderAndContent,
  KitList,
  List,
  Loading,
  Modal,
  Overlay,
  P,
  Paragraph,
  ParagraphSet,
  Quote,
  SectionHeading,
  SubNav,
  Transition,
  TwoColumnLayout,
  TwoColumnList,
  Video,
};
