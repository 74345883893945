const svgs = [
  {
    name: 'rightArrow',
    data: [
      {
        path: {
          d:
            'M30.1,5.3L50,25.1L30.1,45h-6.6l18-17.6H0v-4.8h41.5l-18-17.6h6.6V5.3z',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'warning',
    data: [
      {
        path: {
          d:
            'M42.7,7.2C37.7,2.2,31.5,0,25,0S12.3,2.2,7.3,7.2c-9.7,9.7-9.7,25.4,0,35.2c5,5,11.2,7.2,17.7,7.2s12.7-2.5,17.7-7.2 C52.4,32.6,52.4,16.9,42.7,7.2z M41,40.6c-4.2,4.2-10,6.5-16,6.5s-11.7-2.2-16-6.5c-8.7-8.7-8.7-23,0-31.7c4.3-4.2,10-6.5,16-6.5 s11.7,2.3,16,6.5C49.7,17.6,49.7,31.9,41,40.6z',
        },
      },
      {
        path: {
          d: 'M27,13.1V30h-4V13.1H27z M23,36.5h3.9v-4.3H23V36.5z',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'rightUpArrow',
    data: [
      {
        path: {
          d:
            'M21.8,0.2l28.1-0.1v28.1l-4.5,0l0.1-20.5L16.2,37.1l-3.4-3.4L42.1,4.4L21.8,4.4L21.8,0.2L21.8,0.2z',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'downArrow',
    data: [
      {
        polygon: {
          points: '25,31.3 4.2,10.5 0.1,14.6 25,39.5 50,14.6 45.9,10.5',
          classes: 'arrow',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'plusAndCloseWithCircle',
    data: [
      {
        circle: {
          cx: '25',
          cy: '25',
          r: '22',
          fill: 'none',
          classes: 'circle',
        },
      },
      {
        polygon: {
          points:
            '26.2,15.2 23.8,15.2 23.8,23.9 15,23.9 15,26.4 23.8,26.4 23.8,35.1 26.2,35.1 26.2,26.4 35,26.4 35,23.9 26.2,23.9 ',
          classes: 'plus',
        },
      },
      {
        polygon: {
          points:
            '32.9,19 31.2,17.3 25,23.4 18.8,17.2 17,19 23.3,25.2 17.1,31.3 18.8,33 25,26.9 31.2,33.1 33,31.3 26.7,25.1 ',
          classes: 'close',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'chevron',
    data: [
      {
        polygon: {
          points: '25,31.3 4.2,10.5 0.1,14.6 25,39.5 50,14.6 45.9,10.5',
          classes: 'chevronAndCloseWithCircleChevron',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'chevronAndCloseWithCircle',
    data: [
      {
        circle: {
          cx: '25',
          cy: '25',
          r: '22',
          fill: 'none',
          classes: 'chevronAndCloseWithCircleCircle',
        },
      },
      {
        polygon: {
          points: '25,30.3 15.9,21.2 14.1,23 25,33.9 35.9,23 34.1,21.2',
          classes: 'chevronAndCloseWithCircleChevron',
        },
      },
      {
        polygon: {
          points:
            '32.9,19 31.2,17.3 25,23.4 18.8,17.2 17,19 23.3,25.2 17.1,31.3 18.8,33 25,26.9 31.2,33.1 33,31.3 26.7,25.1 ',
          classes: 'chevronAndCloseWithCircleClose',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'close',
    data: [
      {
        polygon: {
          points:
            '50,5 45,0 25,20 5,0 0,5 20,25 0,45 5,50 25,30 45,50 50,45 30,25',
          classes: 'close',
        },
      },
    ],
    viewBox: '0 0 50 50',
  },
  {
    name: 'play',
    data: [
      {
        polygon: {
          points: '0,0 8,5.5 0,11',
          classes: 'play',
        },
      },
    ],
    viewBox: '0 0 8 11',
  },
  {
    name: 'pause',
    data: [
      {
        polygon: {
          points: '0,0 3,0 3,10 0,10 0,0',
          classes: 'play',
        },
      },
      {
        polygon: {
          points: '6,0 9,0 9,10 6,10 6,0',
          classes: 'play',
        },
      },
    ],
    viewBox: '0 0 9 10',
  },

  {
    name: 'muted',
    height: '30',
    width: '30',
    data: [
      {
        g: {
          transform: 'translate(-97.000000, -48.000000)',
          data: [
            {
              g: {
                transform: 'translate(97.000000, 48.000000)',
                data: [
                  {
                    g: {
                      transform: 'translate(4.000000, 6.000000)',
                      fill: '#FFFEF2',
                      data: [
                        {
                          g: {
                            transform: 'translate(3.000000, 0.573593)',

                            data: [
                              {
                                path: {
                                  d:
                                    'M5.74011671,4.90798034 L13.5001929,12.6680565 C13.8907172,13.0585808 13.8907172,13.6917458 13.5001929,14.0822701 C13.3186245,14.2638385 13.0741693,14.368605 12.8174691,14.374866 L3.81370319,14.59447 L4.03330724,5.59070413 C4.0467736,5.03858358 4.50527237,4.60191807 5.05739292,4.61538443 C5.31409312,4.62164541 5.55854828,4.72641191 5.74011671,4.90798034 Z',
                                  transform:
                                    'translate(9.481906, 8.926267) rotate(45.000000) translate(-9.481906, -8.926267)',
                                },
                              },
                              {
                                polygon: {
                                  points:
                                    '0 7.4516129 4.4516129 6.16129032 4.4516129 11.6912442 0 10.4009217',
                                },
                              },
                            ],
                          },
                        },
                        {
                          path: {
                            d:
                              'M18.353198,12.259593 C18.3633576,11.843048 18.3297871,11.4377194 18.2566733,11.0477941 C17.7619986,8.4096316 15.457178,6.47656906 12.6389123,6.54530725 C9.4041016,6.62420507 18.2743002,15.4944036 18.353198,12.259593 Z',
                            transform:
                              'translate(15.142166, 9.756339) rotate(45.000000) translate(-15.142166, -9.756339)',
                          },
                        },
                        {
                          rect: {
                            x: '10.325976',
                            y: '-4.01495963',
                            width: '2.5',
                            height: '25.5',
                            rx: '1',
                            strokeWidth: '0.5',
                            stroke: '#858480',
                            transform:
                              'translate(11.575976, 8.735040) scale(-1, 1) rotate(58.000000) translate(-11.575976, -8.735040)',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    viewBox: '8 7 12 18',
  },
  {
    name: 'unmuted',
    height: '30',
    width: '30',
    data: [
      {
        g: {
          transform: 'translate(-97.000000, -48.000000)',
          data: [
            {
              g: {
                transform: 'translate(97.000000, 48.000000)',
                data: [
                  {
                    g: {
                      transform: 'translate(4.000000, 6.000000)',
                      fill: '#FFFEF2',
                      data: [
                        {
                          g: {
                            transform: 'translate(3.000000, 0.573593)',

                            data: [
                              {
                                path: {
                                  d:
                                    'M5.74011671,4.90798034 L13.5001929,12.6680565 C13.8907172,13.0585808 13.8907172,13.6917458 13.5001929,14.0822701 C13.3186245,14.2638385 13.0741693,14.368605 12.8174691,14.374866 L3.81370319,14.59447 L4.03330724,5.59070413 C4.0467736,5.03858358 4.50527237,4.60191807 5.05739292,4.61538443 C5.31409312,4.62164541 5.55854828,4.72641191 5.74011671,4.90798034 Z',
                                  transform:
                                    'translate(9.481906, 8.926267) rotate(45.000000) translate(-9.481906, -8.926267)',
                                },
                              },
                              {
                                polygon: {
                                  points:
                                    '0 7.4516129 4.4516129 6.16129032 4.4516129 11.6912442 0 10.4009217',
                                },
                              },
                            ],
                          },
                        },
                        {
                          path: {
                            d:
                              'M18.353198,12.259593 C18.3633576,11.843048 18.3297871,11.4377194 18.2566733,11.0477941 C17.7619986,8.4096316 15.457178,6.47656906 12.6389123,6.54530725 C9.4041016,6.62420507 18.2743002,15.4944036 18.353198,12.259593 Z',
                            transform:
                              'translate(15.142166, 9.756339) rotate(45.000000) translate(-15.142166, -9.756339)',
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    viewBox: '8 7 12 18',
  },
  {
    name: 'aesop',
    data: [
      {
        path: {
          d:
            'M48.6,22.5L31.8,62.2h34.2L48.6,22.5z M28.6,70.3c-3.2,7.4-9.6,21.7-14.9,42c-1.9-0.3-3.5-0.9-6.6-0.9c-3.1,0-5,0.6-7.1,0.9C14.3,81.2,38,28.9,49.4,0c1.3,0.2,2.3,0.5,4,0.5c1.4,0,2.3-0.2,3.5-0.5c12.3,33.7,47.8,111.7,48.1,112.3c-2.7-0.5-5.5-0.9-9.5-0.9c-4.2,0-7.2,0.5-10.3,0.9c-5.6-19.2-12.2-33.2-16.2-42H28.6z',
        },
      },
      {
        path: {
          d:
            'M172.4,66.6c0-5.3,0-26.6-19.2-26.6c-14,0-20.7,10.6-22.2,26.6H172.4z M130.9,73.3c0,4.5,0,13.6,4.3,21c7.4,12.1,17.8,12.5,22.5,12.5c15.7,0,23.1-9.6,25.5-13l1.9,0.4c-1,4.5-1,7.1-1.1,9.6c-7.1,6.7-16.8,10.5-29.8,10.5c-24.4,0-39.3-15.4-39.3-40.1c0-22.1,12.2-40.7,38.5-40.7c35.8,0,35.3,31.1,35.1,39.8H130.9z',
        },
      },
      {
        path: {
          d:
            'M249.6,52.1c-0.6-2.4-1.6-6.4-6.2-9.6c-3.4-2.4-7.8-2.7-10.4-2.7c-9.8,0-14.9,5.1-14.9,12c0,9.3,9.5,12.7,15.1,14.1c14.3,3.7,25.8,6.7,25.8,22.8c0,12.2-8.5,25.8-30.8,25.8c-13,0-22-5.5-23.7-6.7c2.1-3.9,3.4-10.7,3.7-12.8l1.9-0.5c1.1,2.7,2.7,7.1,8.7,10.6c4,2.4,8.2,3,11.7,3c8.4,0,16.2-4,16.2-13.2c0-9.1-5.5-10.9-22.3-16.4c-6.9-2.3-18.3-7.2-18.3-21.3c0-9.1,5.6-23.9,27.8-23.9c11.1,0,17.5,3.4,21.2,5.3c-1.6,4.2-2.7,8-3.2,13.5H249.6z',
        },
      },
      {
        path: {
          d:
            'M291.5,73.5c0,22.3,9.8,34.6,24.5,34.6c15.4,0,25.5-13.3,25.5-34.5c0-12.5-3.9-33.8-24.7-33.8C291.5,39.8,291.5,68.6,291.5,73.5 M357.9,73c0,25.5-17.6,41.5-42,41.5c-17,0-40.8-7.7-40.8-40.6c0-25,16.7-40.6,40.8-40.6 C346.3,33.4,357.9,53.4,357.9,73',
        },
      },
      {
        path: {
          d:
            'M399.4,49.6c-3.2,4-6.6,9.8-6.6,25.7c0,14.1,3.2,19.4,6.6,23.4c4.1,5,9.6,7.9,17,7.9 c22.9,0,22.9-27.1,22.9-32.3c0-24.2-11.6-33-22-33C408.7,41.2,403.1,44.9,399.4,49.6 M419.3,114.7c-15.1,0-23.6-8.3-26.2-14.9 c0,25.2-0.2,39.8,0.8,54.5c-2.4-0.6-4.5-0.9-8.4-0.9c-4,0-6.1,0.3-8.6,0.9c1.3-20.8,1.8-41.9,1.8-62.7c0-14.6-0.3-34.3-0.8-57.6 c2.2,0.5,4.8,0.9,7.9,0.9c2.9,0,5.6-0.5,7.9-0.9c-0.6,8.8-0.6,11.2-0.6,14.6c2.6-4.2,9.5-15.4,27.3-15.4c16,0,35.6,11.7,35.6,40.3 C455.9,98.8,440.3,114.7,419.3,114.7',
        },
      },
      {
        rect: {
          x: '131.8',
          y: '13.5',
          width: '44.3',
          height: '5',
        },
      },
      {
        path: {
          d:
            'M477.2,98.1c0,1.6,0,3,0,4.4c1.3,0,4.3,0,4.3-2.3C481.5,98.1,479.2,98.1,477.2,98.1 M484.9,109.1 c-0.4,0-0.7-0.1-1.2-0.1c-0.4,0-0.8,0.1-1.5,0.1c-2-2.9-3.6-4.5-4.7-5.8h-0.4c0,1.8,0,3.2,0.2,5.8c-0.5,0-0.7-0.1-1.3-0.1 c-0.6,0-0.8,0.1-1.1,0.1c0.1-1.3,0.2-2.7,0.2-6.2c0-3-0.1-4.4-0.1-5.7c0.9,0,1.5,0,2.9,0c2.9,0,5.9,0,5.9,2.8c0,2.5-2.7,2.9-3.9,3 c0.8,1.1,4.4,5.1,5.1,5.9L484.9,109.1z M479.2,94.8c-4.8,0-8.5,3.8-8.5,8.6c0,4.9,3.6,8.6,8.5,8.6c4.9,0,8.5-3.7,8.5-8.6 C487.8,98.7,484.3,94.8,479.2,94.8 M479.2,92.9c5.9,0,10.5,4.7,10.5,10.5c0,5.9-4.7,10.5-10.5,10.5c-5.8,0-10.5-4.6-10.5-10.5 C468.8,97.5,473.5,92.9,479.2,92.9',
        },
      },
    ],
    viewBox: '0 0 489.7 154.3',
  },
];

export default svgs;
