export default {
  // Navigation
  NAV_SHOP: 'NAV_SHOP',
  NAV_READ: 'NAV_READ',
  NAV_VISIT: 'NAV_VISIT',
  NAV_SEARCH: 'NAV_SEARCH',
  NAV_LOGIN: 'NAV_LOGIN',
  NAV_CART: 'NAV_CART',
  NAV_LOGO: 'NAV_LOGO',
  NAV_SHOP_1: 'NAV_SHOP_1',
  NAV_SHOP_2: 'NAV_SHOP_2',
  NAV_SHOP_PRODUCT_RADIO: 'NAV_SHOP_PRODUCT_RADIO',
  NAV_SHOP_PRODUCT_CTA: 'NAV_SHOP_PRODUCT_CTA',
  NAV_VISIT_INPUT: 'NAV_VISIT_INPUT',
  NAV_VISIT_CHECKBOX: 'NAV_VISIT_CHECKBOX',

  // Footer
  FOOTER_LINK: 'FOOTER_LINK',

  // PDP Add to Cart
  ADD_TO_CART_LARGE_CTA: 'ADD_TO_CART_LARGE_CTA',
  ADD_TO_CART_SMALL_CTA: 'ADD_TO_CART_SMALL_CTA',
  ADD_TO_CART_TEST: 'ADD_TO_CART_TEST',

  // Cart
  CART_CHECKOUT_CTA: 'CART_CHECKOUT_CTA',
  CART_PROMO_CTA: 'CART_PROMO_CTA',
  CART_PROMO_INPUT: 'CART_PROMO_INPUT',
  CART_PROMO_AMOUNT: 'CART_PROMO_AMOUNT',
  CART_HEADING: 'CART_HEADING',
  CART_CLOSE: 'CART_CLOSE',

  // Checkout
  CHECKOUT_CUSTOMER_EMAIL: 'CHECKOUT_CUSTOMER_EMAIL',
  CHECKOUT_CUSTOMER_PASSWORD: 'CHECKOUT_CUSTOMER_PASSWORD',
  CHECKOUT_CUSTOMER_PREFIX: 'CHECKOUT_CUSTOMER_PREFIX',
  CHECKOUT_CUSTOMER_FIRST_NAME: 'CHECKOUT_CUSTOMER_FIRST_NAME',
  CHECKOUT_CUSTOMER_LAST_NAME: 'CHECKOUT_CUSTOMER_LAST_NAME',
  CHECKOUT_CUSTOMER_SUBMIT: 'CHECKOUT_CUSTOMER_SUBMIT',
  CHECKOUT_CUSTOMER_GUEST_BUTTON: 'CHECKOUT_CUSTOMER_GUEST_BUTTON',
  ADDRESS_COUNTRY: 'ADDRESS_COUNTRY',
  ADDRESS_LINE_1: 'ADDRESS_LINE_1',
  ADDRESS_LINE_2: 'ADDRESS_LINE_2',
  ADDRESS_COMPANY: 'ADDRESS_COMPANY',
  ADDRESS_REGION: 'ADDRESS_REGION',
  ADDRESS_TOWN: 'ADDRESS_TOWN',
  ADDRESS_POSTAL_CODE: 'ADDRESS_POSTAL_CODE',
  ADDRESS_COUNTRY_CODE: 'ADDRESS_COUNTRY_CODE',
  ADDRESS_PHONE_NUMBER: 'ADDRESS_PHONE_NUMBER',
  CHECKOUT_SHIPPING_METHOD: 'CHECKOUT_SHIPPING_METHOD',
  CHECKOUT_GIFT: 'CHECKOUT_GIFT',
  CHECKOUT_GIFT_MESSAGE_CHECKBOX: 'CHECKOUT_GIFT_MESSAGE_CHECKBOX',
  CHECKOUT_GIFT_MESSAGE: 'CHECKOUT_GIFT_MESSAGE',
  CHECKOUT_GIFT_INSTRUCTIONS_CHECKBOX: 'CHECKOUT_GIFT_INSTRUCTIONS_CHECKBOX',
  CHECKOUT_GIFT_INSTRUCTIONS: 'CHECKOUT_GIFT_INSTRUCTIONS',
  CHECKOUT_SHIPPING_SUBMIT: 'CHECKOUT_SHIPPING_SUBMIT',
  CHECKOUT_PAYMENT_METHOD: 'CHECKOUT_PAYMENT_METHOD',
  CHECKOUT_PAYMENT_CARD_HOLDER: 'CHECKOUT_PAYMENT_CARD_HOLDER',
  CHECKOUT_PAYMENT_CARD: 'CHECKOUT_PAYMENT_CARD',
  CHECKOUT_PAYMENT_EXPIRATION: 'CHECKOUT_PAYMENT_EXPIRATION',
  CHECKOUT_PAYMENT_CVC: 'CHECKOUT_PAYMENT_CVC',
  CHECKOUT_PAYMENT_GIFTCARD_CODE: 'CHECKOUT_PAYMENT_GIFTCARD_CODE',
  CHECKOUT_PAYMENT_GIFTCARD_PIN: 'CHECKOUT_PAYMENT_GIFTCARD_PIN',
  CHECKOUT_BILLING_SAME_SHIPPING: 'CHECKOUT_BILLING_SAME_SHIPPING',
  CHECKOUT_BILLING_TAX: 'CHECKOUT_BILLING_TAX',
  CHECKOUT_PAYMENT_SUBMIT: 'CHECKOUT_PAYMENT_SUBMIT',
  CHECKOUT_SAMPLES_RADIO: 'CHECKOUT_SAMPLES_RADIO',
  CHECKOUT_SAMPLES_SUBMIT: 'CHECKOUT_SAMPLES_SUBMIT',
  CHECKOUT_REVIEW_TOTAL: 'CHECKOUT_REVIEW_TOTAL',
  CHECKOUT_REVIEW_TOS_CHECKBOX: 'CHECKOUT_REVIEW_TOS_CHECKBOX',
  CHECKOUT_REVIEW_SUBMIT: 'CHECKOUT_REVIEW_SUBMIT',

  // Confirmation
  CONFIRMATION_ACCOUNT_BUTTON: 'CONFIRMATION_ACCOUNT_BUTTON',
};
